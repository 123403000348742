import React from 'react'
import { Container, Tab, Tabs } from 'react-bootstrap'
import { TreatmentsTab } from './Treatments'
import { DoctorsTab } from './Doctors'



export const Index: React.FC = () => {

  return (
    <Container className='mt-3'>
      <Tabs>
        <Tab eventKey="billables" title="Facturables" className='p-3 border border-top-0'>
          <TreatmentsTab />
        </Tab>
        <Tab eventKey="doctors" title="Doctores" className='p-3 border border-top-0'>
          <DoctorsTab />
        </Tab>
      </Tabs>
    </Container>
  )


}